<template>
  <v-container fluid class="pa-0" v-if="product">
    <product-header
      v-if="product.status !== 2 && product.status !== 0"
      :product="product"
    />

    <v-container fluid>
      <v-row
        class="grey lighten-3"
        justify="center"
        v-if="product.status !== 2 && product.status !== 0"
      >
        <v-col cols="12" md="8" lg="8">
          <v-card class="rounded-xl mr-2 mb-2 fill-height moholand-shadow">
            <v-card-text class="text-justify black--text" v-if="product">
              <div v-html="product.short_desc"></div>
              <br />
              <div class="fix-html-images" v-html="product.long_desc"></div>
            </v-card-text>
            <v-card-actions class="align-self-end mt-auto">
              <div class="d-flex flex-wrap" v-if="product">
                <v-chip
                  class="ma-2 rounded-lg"
                  text-color="white"
                  label
                  color="orange darken-1"
                  v-for="item in product.tags"
                  :key="item.id"
                >
                  {{ item.tag.name }}
                </v-chip>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-card
            class="rounded-xl mb-4 text-center moholand-shadow"
            v-if="vendor && vendor.user"
          >
            <v-card-text>
              <v-row class="align-center">
                <v-col cols="12" md="6" order-md="1" order-sm="2">
                  <h2>فروشنده</h2>
                  <v-chip
                    class="ma-2 rounded-lg"
                    text-color="white"
                    label
                    color="orange darken-1"
                    @click="
                      vendor ? $router.push('/sellers/' + vendor.user.id) : ''
                    "
                  >
                    {{ vendor.user.first_name + ' ' + vendor.user.last_name }}
                  </v-chip>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  order-md="2"
                  order-sm="1"
                  class="align-center"
                >
                  <v-img
                    :src="
                      vendor && vendor.user
                        ? vendor.user.avatar_url
                        : require('../../assets/img/man.jpg')
                    "
                    class="product-img rounded-xl orange darken-1 mx-auto"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <comments
            :model_id="product.id"
            :model_slug="product.slug"
            comment_type="product"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="12">
          <v-alert type="error">
            محصول مورد نظر رد شده است و مورد تایید موهولند نمی باشد.
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <similar-products :slug="slug" />
  </v-container>
</template>

<script>
import CartButton from '../../components/Pages/CartButton.vue';
import Comments from '../../components/Pages/Comments.vue';
import ProductHeader from '../../components/Pages/ProductHeader.vue';
import SimilarProducts from '../../components/Pages/SimilarProducts.vue';
import MyAxios from '../../constants/MyAxios';
import { getErrorText } from '../../constants/GlobalConstants';
export default {
  components: {
    ProductHeader,
    Comments,
    SimilarProducts,
    CartButton,
  },
  data() {
    return {
      product: null,
      loading: false,
      slug: this.$route.params.slug,
      isWish: null,
      vendor: null,
      contact: {
        name: null,
        email: null,
        desc: null,
      },
    };
  },
  mounted() {
    this.GetData();
  },
  methods: {
    GetData: function() {
      this.$root.$emit('globalLoadingSet', true);
      MyAxios.get('/products/index/' + this.slug, {
        params: {
          incView: true,
          noPaginate: true,
          with: [
            'categories',
            'tags',
            'vendors',
            'vendors.user',
            'features',
            'author',
          ],
        },
      })
        .then(response => {
          const product = response.data[0];
          this.product = product;

          this.setMetaTag('product_id', this.product.id);
          this.setMetaTag('product_name', this.product.name);
          this.setMetaTag('og:image', this.product.media.url, 'property');
          this.setMetaTag(
            'product_price',
            this.product.offer_price ?? this.product.price
          );
          this.setMetaTag(
            'product_old_price',
            this.product.offer_price ? this.product.price : ''
          );
          this.setMetaTag(
            'availability',
            this.product.status == 1 ? 'instock' : 'outofstock'
          );
          this.setMetaTag('guarantee', '');

          this.vendor =
            response.data[0].vendors?.find(
              val => val.user_id == response.data[0].author_id
            ) || null;
          this.$root.$emit('globalLoadingSet', false);
        })
        .catch(error => {
          console.log(error);
        });
    },
    setMetaTag: function(name, content, propName = 'name') {
      document
        .querySelector(`meta[${propName}="${name}"]`)
        .setAttribute('content', content);
    },

    postReport() {
      if (!this.loading) this.loading = true;
      let formData = new FormData();
      formData.append('name', this.desc.value);
      formData.append('desc', this.desc.value);
      // formData.append("images", this.$store.state.clubData.id);
      formData.append('user_id', this.$store.state.userData.id);

      MyAxios.post('client/reports/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 400000,
      })
        .then(response => {
          this.loading = false;
          this.alert = {
            show: true,
            text: 'گزارش شما با موفقیت ثبت شد',
            type: 'success',
          };
          this.modal = false;
          this.desc = {
            value: '',
            show: false,
          };
          console.log(response);
        })
        .catch(error => {
          this.loading = false;
          this.alert = {
            show: true,
            text: getErrorText(error),
            type: 'error',
          };
          console.log(error, error.response);
        });
    },
  },
  watch: {
    '$route.params.slug'(newSlug, oldSlug) {
      this.slug = newSlug;
      this.GetData();
    },
  },
};
</script>

<style scoped>
.product-img {
  border: 5px solid;
  max-height: 150px;
  max-width: 150px;
}
/* .long-desc >>> img {
  width: 100%;
  object-fit: contain;
  height: unset;
} */
</style>
